* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
div.react-confirm-alert-overlay {
  z-index: 9999;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.flags-container {
  display: flex;
}

.flag {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin: 5px;
  filter: grayscale(1);
}

.flag:hover {
  filter: grayscale(0.5);
}

.selected {
  filter: grayscale(0);
}

.selected:hover {
  filter: grayscale(0);
}

.react-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
  border-radius: 4px;
}

.react-tagsinput--focused {
  /* border-color: #a5d24a; */
}

.react-tagsinput-tag {
  background-color: #5b5b5b;
  border-radius: 5px;
  border: 1px solid #5b5b5b;
  color: #fff;
  display: inline-block;
  font-family: sans-serif;
  font-size: 13px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px 10px;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  align-items: center;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}
.react-tagsinput-tag a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  content: "x";
  margin-left: 5px;
  color: #fff;
  text-transform: lowercase;
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
}

.rsw-toolbar {
  position: sticky;
  top: 0;
  
}

.rsw-editor {
  overflow: auto !important ;

}